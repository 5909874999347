/* ==========================================================================
   Console skin
   ========================================================================== */

/* Colors */
$background-color: #000000 !default;
// $text-color: #00d000 !default;
$text-color: #eaeaea !default;
$primary-color: #0fc10f !default;
// $primary-color: #B5E853 !default;
$border-color: mix(#fff, $background-color, 20%) !default;
$code-background-color: mix(#000, $background-color, 15%) !default;
$code-background-color-dark: mix(#000, $background-color, 20%) !default;
$form-background-color: mix(#000, $background-color, 15%) !default;
$footer-background-color: mix(#000, $background-color, 30%) !default;
$link-color: mix($primary-color, $text-color, 40%) !default;
$link-color-hover: mix(#fff, $link-color, 25%) !default;
$link-color-visited: mix(#000, $link-color, 25%) !default;
$masthead-link-color: $primary-color !default;
$masthead-link-color-hover: mix(#000, $primary-color, 20%) !default;
$navicon-link-color-hover: mix(#000, $background-color, 30%) !default;

.author__urls.social-icons .svg-inline--fa,
.page__footer-follow .social-icons .svg-inline--fa {
  color: inherit;
}

.ais-search-box .ais-search-box--input {
  background-color: $form-background-color;
}

.page__title {
    color: $primary-color;
}

.archive__item-title {
    color: $primary-color;
}

code {
    color: $primary-color;
}

